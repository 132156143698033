import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Image from "../../components/image"
import "../../assets/sewer-line-tracing.sass"
import Carousel from 'react-bootstrap/Carousel'

const SewerTracing = () => (
  <div className="sewer-line-tracing-component">
  <SEO title="Sewer line tracing" />
    <div className="service-header">
      <h1>Sewer Line Tracing</h1>
    </div>

    <div className="sewer-ling-tracing-statements sewer-ling-tracing-statements-blue">
      <p>
        Sewer Line Tracing is used by most general contractors. This service helps locate the sewer line, identifies where it’s running from, as well as the sewer line’s depth.
      </p>
    </div>

    <div className="video-container desktop-image-video">
      <video playsInline autoPlay loop={true}>
        <source src={"https://firebasestorage.googleapis.com/v0/b/tonysdrain-ef299.appspot.com/o/line-tracing.mp4?alt=media&token=9869422e-0343-4aba-98ff-9e90697c92ba"} type="video/mp4" />
      </video>
    </div>

    <div className="sewer-ling-tracing-statements desktop-statements">
        <p>
          Our highly-trained and experienced technician uses a top of the line handheld locator, which is receiving a signal in real-time from the camera in the sewer line. Click the botton below to read more about out Camera Scooping service.
        </p>
        <Link to="/services/camerascoping">
          <button>Camera Scoping</button>      
        </Link>
    </div>
    
    <div className="">
      <p className="sewer-ling-tracing-statements sewer-line-statement desktop-statements desktop-statement-view">
        The locator will provide the technician with the exact location and depth of the line, 
        that way we can pinpoint the exact location of the sewer line or the damage.
      </p>
      <div className="desktop-image-video">
        <Carousel>
          <Carousel.Item interval={2500}>
            <div className="scoping-images">
              <Image filename="line-trace.jpg"/>
            </div>
          </Carousel.Item>

          <Carousel.Item interval={3000}>
            <div className="scoping-images">
              <Image filename="tonys-camera-tracker.jpg"/>
            </div>
          </Carousel.Item>

          <Carousel.Item interval={3000}>
            <div className="scoping-images">
              <Image filename="trace.JPG"/>
            </div>
          </Carousel.Item>

        </Carousel>
      </div>
      <p className="sewer-ling-tracing-statements sewer-line-statement desktop-statements mobile-statement-view">
        The locator will provide the technician with the exact location and depth of the line, 
        that way we can pinpoint the exact location of the sewer line or the damage.
      </p>
    </div>



  </div>
)

export default SewerTracing
